import { services } from '../../../../api/feathers';

export const GET_IR_MODELS = 'GET_IR_MODELS';
export const GET_IR_MODEL = 'GET_IR_MODEL';
export const ADD_IR_MODELS = 'ADD_IR_MODELS';
export const UPDATE_IR_MODELS = 'UPDATE_IR_MODELS';
export const REMOVE_IR_MODELS = 'REMOVE_IR_MODELS';

export function getIrModels(query = {}) {
  return (dispatch) =>
    services.irModels.find(query).then((response) =>
      dispatch({
        type: GET_IR_MODELS,
        payload: response.data,
      })
    );
}

export function getIrModel(id) {
  return (dispatch) =>
    services.irModels.get(id).then((response) =>
      dispatch({
        type: GET_IR_MODEL,
        payload: response,
      })
    );
}

export function addIrModels(irModel) {
  return (dispatch) =>
    services.irModels
      .create(irModel)
      .then((response) =>
        dispatch({
          type: ADD_IR_MODELS,
          payload: response,
        })
      )
      .then(() => dispatch(getIrModels()));
}

export function updateIrModels(id, irModel) {
  return (dispatch) =>
    services.irModels
      .patch(id, irModel)
      .then((response) =>
        dispatch({
          type: UPDATE_IR_MODELS,
          payload: response,
        })
      )
      .then(() => dispatch(getIrModels()));
}

export function removeIrModels(id) {
  return (dispatch) =>
    services.irModels.remove(id).then((response) =>
      dispatch({
        type: REMOVE_IR_MODELS,
        payload: response,
      })
    );
}
